.header.is-sticky .navbar {
  background: #09161a;
  animation: slideDown 0.35s ease-out;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.header-home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.section-slider {
  background: url(../images/slider-bg.png) no-repeat center;
  background-size: cover;
  padding-top: 126px;
  padding-bottom: 80px;
  position: relative;
}
.slider-image {
  position: relative;
}
.social-icons {
  position: absolute;
  right: 2%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 170px;
  z-index: 1;
}
.social-icons > a {
  display: block;
  color: #38545d;
  width: 30px;
  text-align: center;
  height: 30px;
  margin: 20px 0;
  font-size: 22px;
}
.slider-image img {
  position: relative;
}
.slider-image .watermark-text {
  color: rgba(255, 255, 255, 0.05);
  font-size: 10rem;
  position: absolute;
  left: 0px;
  font-weight: 700;
  text-transform: uppercase;
  transform: rotate(-90deg);
  right: auto;
  top: 0;
  line-height: normal;
  bottom: 0;
}
.slider-content {
  color: #ffffff;
  padding-left: 10%;
  position: relative;
}
.slider-content h2 {
  font-size: 5.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.slider-content h5 {
  font-weight: 100;
  font-size: 1.125rem;
  line-height: normal;
}
.myaccount .nav-link {
  font-size: 0.938rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  line-height: normal;
}
.myaccount .nav-link:hover,
.myaccount .nav-link:focus {
  letter-spacing: 0;
}
.dropdown-lang .nav-link {
  font-size: 0.938rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  line-height: normal;
}
.searchbox img,
a.cart-item-number img {
  height: 18px;
}
.dropdown-lang .dropdown-menu {
  padding: 0;
  min-width: 5rem;
}
.header-right {
  min-width: 35%;
  justify-content: end;
}
.header-right .searchbox {
  margin-left: 1rem;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
}
.header-right .searchbox .form-control {
  background: #3a4548;
  border: 0;
  border-radius: 50px;
  position: relative;
  width: 260px;
  margin-right: -35px;
  display: inline-block;
  line-height: normal;
  padding: 12px 20px;
  color: #ffffff;
}
.section-discount {
  position: relative;
}
.discount-text {
  position: absolute;
  top: 0;
  color: #ffffff;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 2%;
}
.discount-code {
  font-size: 4.75rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 5rem;
  width: 68%;
}
.discount-code span {
  background: #309bc9;
  padding: 10px 25px;
  font-size: 2rem;
  transform: skewX(-15deg);
  line-height: normal;
  float: right;
  margin-top: 1rem;
}
.discount-percentage {
  text-align: right;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 4.5rem;
  line-height: 8rem;
  font-style: italic;
}
.discount-percentage .percentage {
  font-size: 17rem;
  line-height: 0;
  font-style: italic;
}
.discount-percentage span {
  display: block;
  font-size: 8rem;
}
.category-box {
  background: #fff;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 340px;
  margin-bottom: 1.5rem;
}
.category-box .watermark-text {
  position: absolute;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.02);
  font-size: 12rem;
  font-family: "Chakra Petch", sans-serif;
  left: 30px;
  top: 0;
  line-height: normal;
  transition: all 0.5s ease-out;
}
.category-box h3 {
  font-size: 2.5rem;
  color: #111111;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  transition: all 0.4s ease-out;
  position: relative;
  z-index: 1;
}
.category-box h4 {
  color: #309bc9;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  line-height: normal;
}
.category-box .category-image {
  margin-left: auto;
  width: 35%;
  padding: 30px 0px 0px 0px;
  left: 0;
  transition: all 0.4s ease-out;
}
.shopnow {
  font-size: 0.938rem;
  color: #309bc9;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
}
.shopnow img {
  height: 10px;
  margin-left: 10px;
}
.shopnow:hover,
.shopnow:focus {
  color: #309bc9;
  letter-spacing: 3px;
}
.category-box .category-content {
  padding: 30px 0 30px 40px;
  display: grid;
  align-content: space-between;
}
.category-box .category-image-4 {
  width: 50%;
}
.category-box:hover .watermark-text,
.category-box:focus .watermark-text {
  transition: all 0.5s ease-out;
  left: -20px;
}
.category-box:hover h3,
.category-box:focus h3 {
  transition: all 0.4s ease-out;
  letter-spacing: 2px;
}
.category-box:hover .category-image,
.category-box:focus .category-image {
  left: -10px;
  transition: all 0.4s ease-out;
}
.category-box-accessories {
  background: #3aa0cb;
}
.category-box-accessories h4,
.category-box-accessories .shopnow {
  color: #ffffff;
}
.footer {
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
}
.footer-top {
  padding: 4rem 0 3rem 0;
}
.footer-bottom {
  background: #010a0d;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 0rem;
}
.footer h5 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 2rem;
}
.footerlinks {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footerlinks li {
  margin-bottom: 1.5rem;
}
.footerlinks li a {
  text-transform: uppercase;
  color: #fff;
  font-size: 0.938rem;
  text-decoration: none;
}
.contact-info {
  padding: 0;
  list-style: none;
}
.contact-info li {
  margin-bottom: 1.5rem;
}
.contact-info li.location-name {
  font-weight: 700;
  text-transform: uppercase;
}
.contact-info li span {
  margin-right: 20px;
}
.social-info > a {
  color: #ffffff;
  font-size: 20px;
  margin-right: 15px;
}
.section-advertise-banner img {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}
.section-featured-category {
  padding: 6rem 0rem;
}
.featured-category-image {
  position: relative;
}
.featured-category-image img {
  display: block;
  margin: auto;
  position: relative;
}
.featured-text {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  line-height: 170px;
  text-transform: uppercase;
  text-align: center;
}
.featured-text span {
  font-size: 10rem;
  width: fit-content;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  letter-spacing: 3px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #309bc9;
  text-align: center;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.featured-text span:nth-child(2) {
  -webkit-text-stroke: 1px #ffffff;
  position: relative;
  z-index: 1;
}
.featured-text:hover span,
.featured-text:focus span {
  letter-spacing: 0px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.featured-text:hover span:nth-child(2),
.featured-text:focus span:nth-child(2) {
  letter-spacing: 8px !important;
}
.featured-category-content h4 {
  font-size: 1.063rem;
  text-transform: uppercase;
  color: #309bc9;
}
.featured-category-content h3 {
  font-size: 4.875rem;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 2rem;
}
.custom-text-infographics {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 10rem;
  font-weight: 900;
  line-height: normal;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.custom-text-infographics h5 {
  display: block;
  font-size: 20rem;
  line-height: 20rem;
  font-weight: 900;
  background-image: url("../images/bike-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.custom-text-infographics span {
  position: relative;
  margin-top: -120px;
  display: block;
}
.title h2 {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Chakra Petch", sans-serif;
}
.title h5 {
  font-weight: 300;
  font-size: 1.125rem;
}
.prodcut-box {
  position: relative;
  margin-bottom: 1.5rem;
}
.prodcut-box .product-image {
  background: #ffffff;
  position: relative;
  min-height: 400px;
}
.prodcut-box .product-image .product-thumbnail {
  max-width: 70%;
  max-height: 70%;
  margin: auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.prodcut-box .product-image .tag {
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 15px;
  background: #309bc9;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 15px;
  line-height: normal;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  font-size: 12px;
}
.prodcut-box .product-image .delivered {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 15px;
  line-height: normal;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  font-size: 12px;
  background: #5fc28d;
}
.prodcut-box .product-content {
  color: #ffffff;
  padding-top: 1rem;
}
.product-category-rating .product-category {
  text-transform: uppercase;
  color: #309bc9;
  letter-spacing: 2px;
}
.prodcut-box .product-content .product-category-rating {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.prodcut-box .product-content .product-title-price {
  display: flex;
  justify-content: space-between;
  font-family: "Chakra Petch", sans-serif;
}
.product-title-price .product-price {
  font-weight: 700;
  font-size: 1.25rem;
  text-align: right;
  line-height: normal;
  min-width: 30%;
}
.product-title-price .product-price .old-price {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: line-through;
}
.product-title-price .product-title,
.product-title-price .product-title a {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: normal;
  overflow: hidden;
  margin: 0;
  padding-right: 30px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-title-price .product-title a {
  padding-right: 0rem;
}
.prodcut-box .product-image-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(48, 155, 201, 0.9);
  display: grid;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.prodcut-box:hover .product-image-overlay {
  opacity: 1;
}
.prodcut-box:hover .product-image .tag {
  z-index: 1;
  background: #ffffff;
  color: #309bc9;
}
.prodcut-box:hover .product-image .delivered {
  z-index: 1;
  background: #ffffff;
  color: #5fc28d;
}
.prodcut-box .product-image-overlay .btn {
  min-width: 250px;
  text-align: center;
  display: block;
  margin: 10px 0;
  padding-right: 20px;
  padding-left: 20px;
}
.product-wishlist {
  width: 35px;
  height: 35px;
  border: 1px solid #ffffff;
  text-align: center;
  border-radius: 50%;
  line-height: 35px;
  color: #fff;
  position: absolute;
  background-color: transparent;
  right: 15px;
  top: 15px;
}
.product-share {
  width: 35px;
  height: 35px;
  border: 1px solid #ffffff;
  text-align: center;
  border-radius: 50%;
  line-height: 35px;
  color: #fff;
  position: absolute;
  background-color: transparent;
  right: 15px;
  top: 60px;
}
.product-share:hover,
.product-share:focus,
.product-wishlist:hover,
.product-wishlist:focus {
  background-color: #ffffff;
  color: #309bc9;
}
.section-page-heading {
  padding-top: 8rem;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.section-page-heading .page-heading-watermark {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12rem;
  position: absolute;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.02);
  top: 0;
  line-height: normal;
  left: 10%;
}
.page-title {
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
}
.page-title h1 {
  font-size: 5.5rem;
  font-weight: 700;
}
.page-title h5 {
  font-weight: 400;
  color: #309bc9;
  letter-spacing: 2px;
  font-size: 1.125rem;
}
.page-title h4 {
  margin-top: 2rem;
  font-weight: 300;
  text-transform: none;
  font-size: 17px;
}
.section-cms {
  padding: 4rem 0;
}
.section-partners-logos {
  background: #09161a url("../images/partner_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 5rem 0rem;
}
.partner-logo img {
  display: block;
  margin: auto;
  height: 130px;
  width: auto;
}
.section-al-shaali-branding {
  background: url("../images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5rem 0;
}
.section-al-shaali-branding img {
  display: block;
  margin: auto;
}
.contactinfo-box {
  color: #ffffff;
  margin-bottom: 2rem;
}
.contactinfo-box .contact-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.contact-title h3 {
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  font-family: "Chakra Petch", sans-serif;
  font-size: 4rem;
}
.social-links > a {
  color: #ffffff;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.contactinfo-box .contact-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}
.contactinfo-box .contact-meta > a {
  color: #ffffff;
  text-decoration: none;
}
.mapbox img {
  width: 100%;
  height: auto;
  display: block;
}
.section-contactinfo {
  padding: 4rem 0;
}
.form-group {
  margin-bottom: 2rem;
}
.contact-form .form-control {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 0;
  background: transparent;
  padding: 10px 0 10px 0;
  color: #ffffff;
}
.section-contactform {
  background: rgba(255, 255, 255, 0.05);
  padding: 4rem 0;
}
.section-registration {
  padding: 4rem 0;
}
.section-registration h3 {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.section-registration label {
  font-family: "Chakra Petch", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}
.section-registration .form-control {
  border-radius: 0;
  padding: 0.75rem 0.75rem;
}
.myaccount .dropdown-menu.account-box {
  border-radius: 0;
  background: #313b3f;
  min-width: 20rem;
  padding: 20px;
  right: 0 !important;
  left: auto !important;
}
.account-box .form-field {
  margin-bottom: 1rem;
}
.account-box label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
}
.account-box .form-control {
  border-radius: 0;
  border: 0;
}
.account-box .btn {
  border-radius: 0;
  padding: 8px 0;
  line-height: normal;
  text-align: center;
}
.products-filter-bar {
  margin-bottom: 1rem;
  font-family: "Chakra Petch", sans-serif;
}
.products-filter-bar .sortby {
  background-color: transparent;
  color: #ffffff;
  border-radius: 0;
  border: 0;
  width: 120px;
  margin-left: auto;
  cursor: pointer;
  text-transform: uppercase;
  float: right;
  padding: 12px 0;
}
.products-filter-bar .dropdown-all {
  display: inline-block;
}
.dropdown-all .btn-all {
  text-align: left;
  padding-left: 20px;
  min-width: 200px;
  letter-spacing: normal;
}
.dropdown-all .btn-all:after {
  display: none;
}
.dropdown-all .dropdown-menu {
  width: 100%;
}
.products-filter-bar .form-select {
  background-color: #313b3f;
  border-radius: 0;
  color: #ffffff;
  text-transform: uppercase;
  border: 0;
  padding: 12px 20px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: auto;
  display: inline-block;
  vertical-align: top;
}
.products-filter-bar .sortby option {
  background: #313b3f;
}
.filter-bar .accordion-item {
  background: transparent;
  border: 0;
}
.filter-bar .accordion-button {
  background: transparent;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  padding: 0;
}
.checkbox-selection {
  padding: 0;
  margin: 0 0 2rem 0;
  list-style: none;
}
.checkbox-selection li {
  margin-bottom: 10px;
}
.checkbox-selection li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.checkbox-selection li label {
  position: relative;
  cursor: pointer;
  display: block;
  color: #fff;
  line-height: 24px;
  font-size: 17px;
  font-weight: 300;
  padding-left: 30px;
}
.checkbox-selection li label span {
  float: right;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
}
.checkbox-selection li label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  display: block;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  left: 0;
  top: 2px;
  bottom: 0;
}
.checkbox-selection li input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  bottom: 0;
}
.filter-bar .accordion-body {
  padding: 1rem 0rem 0rem 0rem;
}
.checkbox-selection li label .filter-rating-star {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}
.user-basic-info ul {
  color: #ffffff;
  margin: 0 0 2rem 0;
  list-style: none;
  padding: 0;
  font-family: "Chakra Petch", sans-serif;
}
.user-basic-info ul li {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.user-basic-info ul li span {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  min-width: 140px;
}
.address-box {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1rem;
  min-height: 200px;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.4);
}
.address-box h5 {
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
}
.address-box p {
  color: rgba(255, 255, 255, 0.8);
}
.section-wishlist,
.section-orders {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.single-product-rating {
  margin-bottom: 2rem;
}
.single-product-rating i {
  color: #ffffff;
}
.single-product-rating span {
  color: rgba(255, 255, 255, 0.4);
}
.product-meta {
  margin-top: 2rem;
}
.product-meta h4 {
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}

.checkbox-size-selection {
  padding: 0;
  margin: 0;
  list-style: none;
}
.checkbox-size-selection li {
  margin: 0px 10px 10px 0px;
  display: inline-block;
  vertical-align: top;
}
.checkbox-size-selection li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.checkbox-size-selection li label {
  position: relative;
  cursor: pointer;
  display: block;
  color: rgba(255, 255, 255, 0.4);
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 14px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.checkbox-size-selection li input:checked + label {
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
}

.radio-color-selection {
  padding: 0;
  margin: 0;
  list-style: none;
}
.radio-color-selection li {
  margin: 0px 10px 10px 0px;
  display: inline-block;
  vertical-align: top;
}
.radio-color-selection li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.radio-color-selection li label {
  position: relative;
  cursor: pointer;
  display: block;
  background: #ffffff;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  border: 4px solid #ffffff;
  width: 80px;
  height: 80px;
}
.radio-color-selection li img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.radio-color-selection li input:checked + label {
  border: 4px solid #309bc9;
}
.single-product-price {
  margin-top: 2rem;
}
.single-product-price .new_price {
  font-size: 2.5rem;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.single-product-price .new_price span {
  font-size: 1.3rem;
  margin-left: 2rem;
  color: rgba(255, 255, 255, 0.5);
}
.single-product-price .old_price {
  padding: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-family: "Chakra Petch", sans-serif;
}
.single-product-price .old_price li {
  display: flex;
  align-items: center;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.single-product-price .old_price li span {
  display: inline-block;
  min-width: 130px;
  text-transform: uppercase;
}
.single-product-price .old_price li .discount-off {
  color: #ffffff;
  margin-left: 1rem;
}
.single-product-price .old_price li .strike-out {
  font-size: 1.8rem;
  text-decoration: line-through;
}
.section-single-product {
  padding-bottom: 4rem;
  padding-top: 4rem;
}
.delivery-notes {
  background: #162127;
  padding: 2rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}
.delivery-notes h5 {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Chakra Petch", sans-serif;
  margin-bottom: 1.5rem;
}
.delivery-notes ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.delivery-notes ul li {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  padding-left: 60px;
}
.delivery-notes ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}
.delivery-notes ul li::before {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}
.delivery-notes ul li span {
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}
.single-product-details {
  position: relative;
  padding-left: 60px;
}
.single-product-options {
  position: absolute;
  left: 0;
  top: 0;
}
.single-product-options .product-wishlist,
.single-product-options .product-share {
  right: auto;
}
.single-product-options .product-wishlist {
  top: 0;
}
.single-product-options .product-share {
  top: 50px;
}
.product-tabs .nav-tabs {
  background: #1a2830;
  border: 0;
}
.product-tabs .nav-tabs .nav-item {
  width: 25%;
}
.product-tabs .nav-tabs .nav-item .nav-link {
  font-family: "Chakra Petch", sans-serif;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  padding: 15px 0;
  display: block;
  width: 100%;
  margin: 0;
}
.product-tabs .nav-tabs .nav-item.show .nav-link,
.product-tabs .nav-tabs .nav-link.active {
  background-color: #1f3039;
  color: #ffffff;
}
.product-tabs .tab-content {
  background: #162127;
  padding: 2rem;
  color: #ffffff;
}
.product-add-car.text-end {
  display: flex;
  justify-content: end;
}
.product-add-car .form-select {
  width: auto;
  background-color: #09161a;
  color: #ffffff;
  border-radius: 0;
  margin-right: 1rem;
  text-align: center;
  min-width: 80px;
}
.section-bought-together {
  background: #162127;
  padding: 3rem 0rem;
}
.product-group-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-group-item {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
}
.product-group-item li {
  margin: 0px 25px;
  position: relative;
  width: 200px;
}
.product-group-item li::after {
  content: "+";
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  right: 0;
  width: 30px;
  height: 60px;
  margin: auto;
  top: -80px;
  bottom: 0;
  left: auto;
  right: -40px;
}
.product-group-item li:last-child::after {
  display: none;
}
.product-group-item li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.product-group-item li label {
  position: relative;
  cursor: pointer;
  display: block;
  color: #fff;
}
.product-group-item li label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #222;
  display: block;
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 15px;
  top: 15px;
  bottom: 0;
  border-radius: 3px;
  z-index: 1;
}
.product-group-item li input:checked + label::before {
  background-color: #309bc9;
  border: 1px solid #309bc9;
}
.product-group-item li input:checked + label::after {
  content: "";
  position: absolute;
  left: 24px;
  top: 18px;
  width: 8px;
  height: 14px;
  border: solid #ffffff;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
.product-group-item .product-group-item-image {
  background: #ffffff;
  padding: 20px;
  text-align: center;
  min-height: 180px;
  position: relative;
}
.product-group-item .product-group-item-image img {
  display: block;
  margin: auto;
  max-height: 90%;
  max-width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.product-group-item .product-group-item-price {
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin: 10px 0;
}
.product-group-item h4 {
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Chakra Petch", sans-serif;
  text-align: center;
}
.product-group-cart {
  color: #ffffff;
}
.product-group-cart .product-group-price {
  color: #309bc9;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  margin-bottom: 1rem;
}
.product-group-cart h6 {
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}
.megamenu {
  width: 100%;
  background: #111d25;
  margin-top: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem 0 2rem 0;
  left: 0;
  top: 100%;
}
.megamenu h4 {
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
}
.megamenu .megamenu-links {
  padding: 0;
  list-style: none;
  margin: 0 0 2rem 0;
}
.megamenu .megamenu-links li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  display: block;
  line-height: 32px;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
}
.menu-product {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: block;
  text-decoration: none;
  min-height: 350px;
  background-color: #fff;
}
.menu-product::before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.menu-product .menu-product-content {
  position: absolute;
  color: #ffffff;
  bottom: 0;
  padding: 10px;
  left: 0;
  width: 100%;
}
.menu-product-content h5 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
}
.menu-product-content .menu-product-price {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
}
.menu-product-content .menu-product-cat {
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.menu-product .menu-product-tag {
  background: #309bc9;
  color: #ffffff;
  position: relative;
  display: inline-block;
  line-height: normal;
  padding: 5px 15px;
  margin: 10px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
}
.btn img {
  margin-left: 10px;
}
.cart-itembox {
  background-color: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cart-itembox h2 {
  font-weight: 800;
  font-size: 1.25rem;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.cart-itembox h3 {
  font-weight: 900;
  font-size: 1.6rem;
  color: #309bc9;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.cart-itembox h3 span {
  font-weight: 500;
  font-size: 0.6rem;
  color: #313b3f;
  letter-spacing: 2px;
  margin-left: 1rem;
}
.cart-itembox .cart-item-image {
  width: 40%;
  padding-right: 20px;
}
.cart-itembox .cart-item-image img {
  height: auto;
  width: 80%;
  display: block;
  margin: auto;
}
.cart-itembox .cart-item-content {
  width: 60%;
}
.quantity-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quantity-btns .form-select {
  width: auto;
  text-align: center;
  border-radius: 0;
  border: 1px solid #09161a;
  color: #09161a;
  font-weight: 500;
  padding: 5px 40px 5px 15px;
}
.quantity-btns .btn-cart {
  background: transparent;
  border: 0;
  color: #777777;
  width: 30px;
  margin-right: auto;
  margin-left: 10px;
}
.quantity-btns .add-to-wishlist {
  border: 1px solid #09161a;
  background: #fff;
  line-height: normal;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 15px;
  font-weight: 500;
  color: #4b4b4b;
  font-family: "Chakra Petch", sans-serif;
}
.common-box {
  background: #162127;
  padding: 30px;
  margin-bottom: 1rem;
}
.common-box label {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
  font-family: "Chakra Petch", sans-serif;
}
.common-box .form-control {
  background: #1a2b34;
  border-radius: 0;
  border: 0;
  padding: 12px 15px;
  color: #ffffff;
}
.cart-total-calculations {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-family: "Chakra Petch", sans-serif;
  margin: 2rem 0;
}
.item-delivery-date span {
  display: block;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
}
.item-pricing ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.item-pricing ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
}
.item-pricing ul li .item-value {
  font-size: 22px;
  font-weight: 700;
  margin-left: 2rem;
  color: rgba(255, 255, 255, 1);
}
.item-pricing ul li.discount {
  font-weight: 700;
}
.cart-item-total-price {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: end;
}
.cart-item-total-price span {
  margin: 0 1rem;
  color: rgba(255, 255, 255, 0.4);
}
.cart-item-total-price .total-price {
  color: #309bc9;
  font-size: 3rem;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
}
.cod {
  position: relative;
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  line-height: normal;
  margin-top: 1rem;
}
.cod:before {
  content: "\f058";
  font-family: "Fontawesome";
  margin-right: 10px;
}
.address-selection {
  padding: 0;
  margin: 0;
  list-style: none;
}
.address-selection li {
  margin: 0px 0px 20px 0px;
  padding-left: 50px;
  display: block;
  position: relative;
}
.address-selection li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.address-selection li label {
  cursor: pointer;
  display: block;
  background: transparent;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
}
.address-selection li label h5 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}
.address-selection li input:checked + label {
  border: 1px solid rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}
.address-selection li label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  display: block;
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 50px;
  margin: auto;
}
.address-selection li input:checked + label::before {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.address-selection li input:checked + label::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 0px;
  width: 8px;
  height: 15px;
  border: solid #162127;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: auto;
  bottom: 2px;
}
.cstm-btn {
  padding: 12px 0px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 400;
  border: 1px solid #ffffff;
  line-height: 22px;
  width: 100%;
  text-align: center;
  background: transparent;
}
.cstm-btn:hover,
.cstm-btn:focus {
  letter-spacing: 1px;
  color: #ffffff;
}
.checkmark {
  color: #7dcf65;
  width: 100%;
  text-align: center;
  background: #1a2b34;
  padding: 12px 0;
  border: 0;
}

.go4197845992 {
  font-size: 18px !important;
}

.modal .modal-content .form-control {
  color: #000000 !important;
  border-radius: 0;
  padding: 0.8rem 0.75rem;
}
.product-rating li {
  margin: 0;
}
.title h2 {
  font-size: 2rem;
}

.go4197845992 {
  font-size: 18px !important;
}

.modal .modal-content .form-control {
  color: #000000 !important;
  border-radius: 0;
  padding: 0.8rem 0.75rem;
}
.modalAddcart h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 2px;
  margin-top: 2rem;
}
.modalAddcart h4 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #309bc9;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.modalAddcart .modal-body {
  padding: 4rem 2rem;
}

.modalPlaceorder h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 2px;
  margin-top: 2rem;
}
.modalPlaceorder h5 {
  font-size: 1.125rem;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.order-overview-info {
  color: #ffffff;
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  line-height: 2.5rem;
  font-size: 1.25rem;
}
.order-overview-info li span {
  font-weight: 700;
}
.modalPlaceorder .modal-body {
  padding: 4rem 2rem;
}
a.cart-item-number {
  position: relative;
}
a.cart-item-number span {
  background: #309bc9;
  color: #ffffff;
  width: 25px;
  height: 25px;
  position: absolute;
  right: -10px;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  top: -20px;
}
.filter-bar {
  padding-right: 15%;
}
.bigImage {
  background: #ffffff;
  position: relative;
  padding: 30px !important;
  margin-bottom: 1rem;
}
.product-gallery {
  padding-left: 140px;
  position: relative;
}
.product-gallery .smallImage {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 0px);
  padding: 40px 0;
}
.smallImage .swiper-wrapper {
}
.smallImage .swiper-wrapper .swiper-slide {
  background: #ffffff;
  text-align: center;
  padding: 15px;
}
.smallImage .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.smallImage .swiper-button-prev {
  position: absolute;
  top: 0;
  color: #ffffff;
  background: #309bc9;
  width: 30px;
  height: 30px;
  font-size: 12px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  text-align: center;
}
.smallImage .swiper-button-prev::after {
  font-size: 18px;
  transform: rotate(90deg);
}
.smallImage .swiper-button-next {
  position: absolute;
  top: auto;
  bottom: 0px;
  color: #ffffff;
  background: #309bc9;
  width: 30px;
  height: 30px;
  font-size: 12px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  text-align: center;
}
.smallImage .swiper-button-next::after {
  font-size: 18px;
  transform: rotate(90deg);
}
.smallImage .swiper-button-prev.swiper-button-disabled,
.smallImage .swiper-button-next.swiper-button-disabled {
  opacity: 1;
  cursor: auto;
  pointer-events: none;
}
.form-resetpassword label {
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.form-resetpassword .form-control {
  line-height: 30px;
  border-radius: 0px;
  padding: 10px 15px;
}
.section-resetpassword {
  padding-top: 1rem;
  padding-bottom: 6rem;
}
.filter-bar .button-filter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  background: #313b3f;
  border: 0;
  color: #ffffff;
  align-items: center;
  font-size: 18px;
  padding: 5px 10px;
  display: none;
}
.filter-bar .offcanvas {
  position: static;
  transform: none;
  background: transparent;
  visibility: visible;
  width: auto;
}
.filter-bar .offcanvas-header {
  display: none;
}
.errorAll {
  color: #f17979;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 400;
}

.pricerange-filter {
  margin-bottom: 2rem;
}
.range-slider .range-slider__thumb {
  width: 20px !important;
  height: 20px !important;
  background: #ffffff !important;
}
.range-slider {
  height: 3px !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.range-slider .range-slider__range {
  background: #309bc9 !important;
  top: 0 !important;
  transform: none !important;
}
.pricerange-filter .price-value-input {
  border: 1px solid #ffffff;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px 0;
  text-align: center;
  margin-right: 25px;
  width: auto;
}
/* Chrome, Safari, Edge, Opera */
.price-value-input::-webkit-outer-spin-button,
.price-value-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.price-value-input[type="number"] {
  -moz-appearance: textfield;
}
.product-tabs {
  margin-top: 2rem;
}
.write-review label {
  display: block;
  margin-bottom: 5px;
}
.write-review .form-control {
  background: #1f3039;
  border: 0;
  color: #fff;
}
.searched-items {
  background: #3a4548;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50px;
  min-width: 260px;
  padding: 5px 10px;
  list-style: none;
  z-index: 11;
  max-height: 50vh;
  overflow-y: auto;
}
.searched-items > a {
  display: flex;
  background: #ffffff;
  color: #222222;
  margin: 5px 0;
  font-weight: 500;
  align-items: center;
  line-height: normal;
  padding: 10px 0;
  font-size: 14px;
  border-radius: 10px;
  text-decoration: none;
  position: relative;
  padding-left: 70px;
  min-height: 70px;
  overflow: hidden;
}
.searched-items > a span {
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  top: 0;
  bottom: 0;
  padding: 5px;
}
.searched-items > a span img {
  height: 100%;
  width: 100%;
  display: block;
  margin: auto;
  object-fit: cover;
}

.size-chart-table {
  color: #ffffff;
}

.arrow {
  height: 1px;
  width: 30px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 15px;
  vertical-align: middle;
  transition: all 0.6s ease;
  background: #ffffff;
}
.arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: -5px;
  right: 0px;
  transition: all 0.5s ease;
}
.arrow-animation:hover .arrow {
  width: 60px;
  margin-left: 10px;
  transition: all 0.6s ease;
}

.arrow-dark {
  height: 1px;
  width: 50px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 15px;
  vertical-align: middle;
  transition: all 0.6s ease;
  background: #09161a;
}
.arrow-dark:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #09161a;
  position: absolute;
  top: -5px;
  right: 0px;
  transition: all 0.5s ease;
}
.arrow-animation:hover .arrow-dark {
  width: 70px;
  margin-left: 10px;
  transition: all 0.6s ease;
}
.pagination .page-item {
  margin: 0 10px;
}
.pagination .page-item .page-link {
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  border-radius: 5px;
  border: 0;
  background: #313b3f;
  color: #ffffff;
}
.pagination .page-item.active .page-link {
  background: #309bc9;
}
.pagination .page-item.disabled {
  opacity: 0.6;
}
