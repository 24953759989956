

/*Arabic*/
html[dir=rtl] {
    body, html {
        direction: rtl;  /* Right to Left */
        text-align: right;
    }
    .me-4 {
        margin-left: 1.5rem !important;
        margin-right: auto;
    }
    .category-box .category-image {
        margin-right: auto;
    }
    .category-box .category-content {
        padding: 30px 30px 30px 0px;
    }
    .shopnow img {
        margin-left: 0;
        margin-right: 10px;
        transform: rotate(180deg);
    }
    .product-wishlist,
    .product-share {
        left: 15px;
        right: auto;
    }
    .prodcut-box .product-image .tag {
        right: 15px;
        left: auto;
    }     
    .btn img {
        margin-right: 10px;
        margin-left: 0;
        transform: rotate(180deg);
    }
    .product-title-price .product-price {
        text-align: left;
    }
    .product-title-price .product-title, .product-title-price .product-title a {
        padding-left: 30px;
        padding-right: 0;
    }
    .section-page-heading .page-heading-watermark {
        right: 10%;
        left: auto;
        font-size: 13rem;
    }
    .checkbox-selection li label {
        padding-right: 30px;
        padding-left: 0;
    }
    .checkbox-selection li label::before {
        right: 0;
        left: auto;
    }
    .checkbox-selection li label span {
        float: left;
    }
    .checkbox-selection li input:checked + label::after {
        right: 5px;
        left: auto;
    }
    .product-add-car .form-select {
        margin-left: 1rem;
        margin-right: 0;
    }
    .product-gallery {
        padding-right: 140px;
    }
    .product-gallery .smallImage {
        right: 0;
        left: auto;
    }
    .single-product-price .new_price span {
        margin-right: 2rem;
        margin-left: 0;
    }
    .single-product-price .old_price li .discount-off {
        margin-right: 1rem;
        margin-left: 0rem;
    }
    .cod::before {
        margin-left: 10px;
        margin-right: 0;
    }
    .item-pricing ul li .item-value {
        margin-right: 2rem;
        margin-left: 0rem;
    }
    .modal-header .btn-close {
        margin: -.5rem auto -.5rem -.5rem;
    }
    .dropdown-lang .dropdown-menu {
        left: 0 !important;
        right: auto !important;       
    }
    .myaccount .dropdown-menu.account-box {
        left: 0 !important;
        right: auto !important;
        text-align: right;
    }
    
    

}