@media only screen and (max-width: 767px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
  }
  .navbar-brand img {
    height: 60px;
  }
  .slider-image .watermark-text {
    display: none;
  }
  .slider-image img {
    height: 300px;
    display: block;
    margin: auto;
  }
  .slider-content {
    padding-left: 0;
    text-align: center;
  }
  .slider-content h2 {
    font-size: 2.5rem;
  }
  .buttons-more .btn {
    padding: 0.6rem 1rem;
  }
  .discount-text {
    position: static;
    padding-top: 2rem;
  }
  .discount-code {
    font-size:1.5rem;
    line-height: normal;
    width: 100%;
  }
  .myaccount .dropdown-menu.account-box {
    border-radius: 0;
    background: #313b3f;
    min-width: 16rem;
    padding: 10px;
    right: 0 !important;
    left: auto !important;
    transform: none !important;
    top: 100% !important;
  }
  .discount-code span {
    padding: 5px 25px;
    font-size: 1rem;
    line-height: normal;
    margin: 10px 0 0 0;
  }
  .discount-percentage {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .discount-percentage .percentage {
    font-size: 8rem;
  }
  .discount-percentage span {
    font-size: 4rem;
    margin-right: 15px;
    display: inline-block;
  }
  .custom-text-infographics h5 {
    font-size: 5.5rem;
    line-height: 6rem;
    font-weight: 900;
  }
  .custom-text-infographics {
    font-size: 4rem;
    padding-top: 0;
  }
  .custom-text-infographics span {
    margin-top: -60px;
  }
  .featured-text span {
    font-size: 6rem;
  }
  .featured-text {
    line-height: 100px;
  }
  .featured-category-image {
    margin-top: 2rem;
  }
  .section-featured-category {
    padding: 2rem 0rem;
  }
  .footer-top {
    padding: 2rem 0 2rem 0;
  }
  .footer h5 {
    margin-bottom: 1rem;
  }
  .footerlinks li {
    margin-bottom: 1rem;
  }
  .footerlinks {
    margin: 0 0 2rem 0;
  }
  .contact-info li {
    margin-bottom: 1rem;
    line-height: normal;
  }
  .page-title h1 {
    font-size: 2rem;
  }
  .page-title h4 {
    margin-top: 1rem;
  }
  .section-al-shaali-branding {
    padding: 2rem 0;
  }
  .section-al-shaali-branding img {
    height: 60px;
  }
  .section-page-heading .page-heading-watermark {
    color: rgba(255, 255, 255, 0.05);
    left: 5%;
  }
  .section-partners-logos {
    padding: 2rem 0rem;
  }
  .contact-title h3 {
    font-size: 3rem;
  }
  .mapbox {
    margin-bottom: 2rem;
  }
  .section-contactinfo {
    padding: 2rem 0;
  }
  .delivery-notes {
    padding: 1.5rem;
    margin: 1rem 0rem;
  }
  .delivery-notes ul li::before {
    width: 30px;
    height: 30px;
  }
  .delivery-notes ul li {
    padding-left: 50px;
  }
  .product-tabs .nav-tabs .nav-item .nav-link {
    font-size: 12px;
  }
  .single-product-details {
    padding-left: 0px;
  }
  .single-product-price .new_price {
    font-size: 2rem;
  }
  .single-product-price .old_price {
    font-size: 1.25rem;
  }
  .single-product-price .old_price li span {
    min-width: 100px;
  }
  .single-product-price .old_price li .strike-out {
    font-size: 1.5rem;
  }
  .single-product-price .new_price span {
    font-size: 1rem;
    margin-left: 1rem;
  }
  .single-product-options {
    margin-bottom: 1rem;
  }
  .single-product-options,
  .product-wishlist,
  .product-share {
    /* position: static;
    display: inline-block;
    margin-right: 10px; */
  }
  .section-page-heading .page-heading-watermark {
    font-size: 6rem;
    left: 5%;
  }
  .product-add-car.text-end {
    margin-bottom: 2rem;
  }
  .checkbox-size-selection li label {
    font-size: 16px;
    padding: 6px 15px;
  }
  .product-group-items {
    display: block;
  }
  .product-group-item {
    display: block;
  }
  .product-group-item li {
    width: 60%;
    margin: auto auto 60px auto;
    position: relative;
  }
  .product-group-item li::after {
    margin: auto;
    top: auto;
    bottom: -60px;
    left: 0;
    right: 0;
  }
  .cart-itembox h2 {
    font-size: 1rem;
  }
  .cart-itembox h3 {
    font-size: 1rem;
    letter-spacing: 2px;
  }
  .cart-itembox .cart-item-image {
    width: 30%;
  }
  .cart-itembox h3 span {
    letter-spacing: 1px;
    margin-left: 10px;
  }
  .quantity-btns .add-to-wishlist {
    font-size: 12px;
    padding: 8px 10px;
  }
  .common-box {
    padding: 20px;
  }
  .address-selection li label {
    font-size: 16px;
    padding: 10px;
  }
  .address-selection li label h5 {
    font-size: 18px;
  }
  .btn {
    padding: 0.6rem 1rem;
    font-size: 0.875rem;
  }
  .cart-total-calculations {
    display: block;
  }
  .cart-item-total-price .total-price {
    font-size: 2rem;
  }
  .section-single-product {
    padding-bottom: 2rem;
  }
  .section-wishlist,
  .section-orders {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .user-basic-info ul li span {
    display: block;
    min-width: auto;
    font-weight: 400;
  }
  .user-basic-info ul li {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .address-box {
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 1rem;
    min-height: auto;
    margin-top: 1rem;
  }
  .section-registration {
    padding: 2rem 0;
  }
  .prodcut-box .product-image {
    min-height: 300px;
  }
  .featured-category-content h3 {
    font-size: 3rem;
  }
  .category-box .watermark-text {
    font-size: 10rem;
    left: 0;
  }
  .category-box .category-content {
    padding: 20px 0 20px 20px;
  }
  .shopnow img {
    height: 8px;
    margin-left: 5px;
  }
  .category-box {
    min-height: auto;
  }
  .category-box h3 {
    font-size: 1.8rem;
  }
  .category-box .category-image {
    width: 150px;
  }
  .header-right {
    margin-right: 10px !important;
    margin-left: auto;
  }
  #navbarSupportedContent.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #313b3f;
    position: absolute;
    top: 86px;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 5px 0px;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 10px 20px;
  }
  .menu-product {
    margin-bottom: 1rem;
  }
  .megamenu h4 {
    font-size: 1rem;
  }
  .megamenu .megamenu-links {
    margin: 0 0 1rem 0;
  }
  .megamenu .megamenu-links li a {
    line-height: 28px;
    font-size: 14px;
  }
  .section-slider {
    padding-top: 76px;
    padding-bottom: 2rem;
  }
  .slider-content h5 {
    font-weight:300;
    font-size: 1rem;
  }
  .filter-bar {
    padding: 0;
  }
  .filter-bar .button-filter {
    display: flex;
  }
  .filter-bar .offcanvas {
    visibility: hidden;
    position: fixed;
    width: 100%;
    background-color: #313b3f;
    transform: translateX(-100%);
  }
  .filter-bar .offcanvas .accordion {
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
  }
  .filter-bar .offcanvas.show {
    visibility: visible;
    transform: none;
  }
  .filter-bar .offcanvas-header {
    display: flex;
  }
  .filter-bar .accordion-button {
    font-size: 1rem;
  }
  .checkbox-selection li label {
    font-size: 14px;
    font-weight: 500;
  }
  .checkbox-selection li label span {
    font-size: 14px;
    font-weight: 500;
  }
  .footerlinks li a {
    font-size: 14px;
    display: block;
    line-height: normal;
  }
  .footer-logo img {
    height: 80px;
    margin-bottom: 1rem;
  }
  .dropdown-lang .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }
  .header-right .searchbox .form-control {
    position: fixed;
    width:90%;
    color: #ffffff;
    left:5%;
    z-index: 1;
    top: 76px;
    border-radius: 10px;
  }
  .searched-items {
    left: 5%;
    right: 5%;
    top: 120px;
    min-width: 90%;
    position: fixed;
  }
  .social-icons {
    display: none;
  }
}
